@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');
.footer-container {
  background-color: var(--primary-color);

  .contact-address {
    color: white;
    margin-bottom: 20px;

    .location-marker {
      margin-top: 6px;
      margin-right: 10px;
    }

    .laptop-icon {
      font-size: 14px;
      margin-top: 9px;
    }

    p {
      // text-align: center;
      color: white;
      font-family: 'Lato', sans-serif;
      font-size: 16px;
    }

    h2 {
      color: #fed136;
      margin-bottom: 15px;
      font-family: sans-serif;
      font-size: 22px;
    }
  }
  .list-inline-item {
    a:active {
      background-color: transparent !important;
    }
    a:hover {
        background-color: #fed136 !important;
    }
  }
  .quicklinks {
    .list-inline-item {
      display: block;
    }
  }

  .copyright {
    color: white;
  }

  #credits {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 768px) {
  .last-para {
    margin-bottom: 50px;
  }
}
