@import url('https://fonts.googleapis.com/css2?family=GFS+Didot&display=swap');
@font-face {
    font-family: 'AppleChancery';
    src: url('../../assets/fonts/Apple\ Chancery.ttf');
}

.logobar-container {
    height: 80px;
    width: 100vw;
    background-color: white;
    margin-top: 0px;
    position: fixed;
    z-index: 2000;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    .home-link {
        text-decoration: none;
        display: flex;
        flex-direction: row;

        #align-infra-logo {
            height: 70px;
            width: 90px;
            margin-left: 30px;
            margin-top: 5px;
        }

        .text-container {
            display: flex;
            flex-direction: column;
            padding-top: 10px;

            #main-head-align {
                font-size: 22px;
                font-family: 'GFS Didot', serif;
                font-weight: bold;
                color: rgb(0,36,61);
            }

            div {
                height: 1px;
                width: 140px;
                background-color: rgb(0,36,61);
            }

            #sub-head-building {
                color: rgb(0,36,61);
                font-size: 12px;
                font-family: AppleChancery;
                font-style: italic;
                padding-top: 5px;
            }
        }
    }
}