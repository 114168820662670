body {
	overflow-x: hidden;
	font-family: 'Roboto Slab',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}

p {
	line-height: 1.75;
}

a {
	color: #fed136;
}

a:hover {
	color: #fec503;
}

.text-primary {
	color: #fed136!important;
}

.text-uppercase {
	text-align: center;
}

h1,h2,h3,h4,h5,h6 {
	font-weight: 700;
	font-family: Montserrat,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}

.page-section {
	padding: 100px 0;
}

.page-section h2.section-heading {
	font-size: 40px;
	margin-top: 0;
	margin-bottom: 35px;
}

.page-section h3.section-subheading {
	font-size: 16px;
	font-weight: 400;
	font-style: italic;
	margin-bottom: 75px;
	text-transform: none;
	font-family: 'Droid Serif',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}

@media (min-width:768px) {
	section {
		padding: 150px 0;
	}
}

::-moz-selection {
	background: var(--primary-color);
	text-shadow: none;
}

::selection {
	background: var(--primary-color);
	text-shadow: none;
}

img::-moz-selection {
	background: 0 0;
}

img::selection {
	background: 0 0;
}

img::-moz-selection {
	background: 0 0;
}

.btn {
	font-family: Montserrat,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
	font-weight: 700;
}

.btn-xl {
	font-size: 18px;
	padding: 20px 40px;
}

.btn-primary {
	background-color: var(--primary-color);
	border-color: var(--primary-color);
}

.btn-primary:active,.btn-primary:focus,.btn-primary:hover {
	background-color: var(--secondary-color) !important;
	background-color: #fff;
	border-color: var(--secondary-color) !important;
	color: #fff;
}

/* .btn-primary:active,.btn-primary:focus {
	-webkit-box-shadow: 0 0 0 .2rem var(--primary-color)!important;
	box-shadow: 0 0 0 .2rem var(--primary-color)!important;
} */

#mainNav .navbar-toggler {
	font-size: 12px;
	right: 0;
	padding: 13px;
	text-transform: uppercase;
	color: #fff;
	border: 0;
	background-color: #fed136;
	font-family: Montserrat,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}

#mainNav .navbar-brand {
	color: #fed136;
	font-family: 'Kaushan Script',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}

#mainNav .navbar-brand.active,#mainNav .navbar-brand:active,#mainNav .navbar-brand:focus,#mainNav .navbar-brand:hover {
	color: #fec503;
}

#mainNav .navbar-nav .nav-item .nav-link {
	font-size: 90% !important;
	font-weight: 400;
	padding: .75em 0;
	letter-spacing: 1px;
	color: #fff;
	font-family: Montserrat,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}

#mainNav .navbar-nav .nav-item .nav-link.active,#mainNav .navbar-nav .nav-item .nav-link:hover {
	color: #fed136;
}

@media (min-width:992px) {
	#mainNav {
		padding-top: 25px;
		padding-bottom: 25px;
		-webkit-transition: padding-top .3s,padding-bottom .3s;
		transition: padding-top .3s,padding-bottom .3s;
		border: none;
		background-color: transparent;
	}

	#mainNav .navbar-brand {
		font-size: 1.75em;
		-webkit-transition: all .3s;
		transition: all .3s;
	}

	#mainNav .navbar-nav .nav-item .nav-link {
		padding: 1.1em 1em!important;
	}

	#mainNav.navbar-shrink {
		padding-top: 0;
		padding-bottom: 0;
		background-color: #212529;
	}

	#mainNav.navbar-shrink .navbar-brand {
		font-size: 1.25em;
		padding: 12px 0;
	}
}

header.masthead {
	text-align: center;
	color: #fff;
	/* background-image: url(../img/infra.jpg); */
	background-repeat: no-repeat;
	background-attachment: scroll;
	background-position: center center;
	background-size: cover;
}

header.masthead .intro-text {
	padding-top: 150px;
	padding-bottom: 100px;
}

header.masthead .intro-text .main-head-container {
	background-color: rgb(218,222,228);
	opacity: 0.7;
	width: 330px;
	height: 130px;
	margin: auto;
	margin-bottom: 30px;
	margin-top: 50px;
}

header.masthead .intro-text .main-head-container .intro-lead-in {
	font-size: 22px;
	font-style: italic;
	line-height: 22px;
	margin-bottom: 25px;
	font-family: 'Droid Serif',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}

header.masthead .intro-text .main-head-container .intro-heading {
	font-size: 50px;
	font-weight: 700;
	line-height: 50px;
	margin-bottom: 25px;
	margin-top: -100px;
	font-family: Montserrat,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}

@media (min-width:768px) {
	header.masthead .intro-text {
		padding-top: 300px;
		padding-bottom: 200px;
	}

	header.masthead .intro-text .main-head-container {
		width: 700px;
		height: 200px;
	}

	header.masthead .intro-text .main-head-container .intro-lead-in {
		font-size: 40px;
		font-style: italic;
		line-height: 40px;
		margin-bottom: 25px;
		font-family: 'Droid Serif',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
	}

	header.masthead .intro-text .main-head-container .intro-heading {
		font-size: 75px;
		font-weight: 700;
		line-height: 75px;
		margin-bottom: 50px;
		font-family: Montserrat,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
	}
}

@media (max-width: 340px) {
	header.masthead .intro-text .main-head-container {
		width: 250px;
	}
}

.service-heading {
	margin: 15px 0;
	text-transform: none;
}

#portfolio .portfolio-item {
	right: 0;
	margin: 0 0 15px;
}

#portfolio .portfolio-item .portfolio-link {
	position: relative;
	display: block;
	max-width: 400px;
	margin: 0 auto;
	cursor: pointer;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover {
	position: absolute;
	width: 100%;
	height: 100%;
	-webkit-transition: all ease .5s;
	transition: all ease .5s;
	opacity: 0;
	background: rgba(254,209,54,.9);
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover:hover {
	opacity: 1;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content {
	font-size: 20px;
	position: absolute;
	top: 50%;
	width: 100%;
	height: 20px;
	margin-top: -12px;
	text-align: center;
	color: #fff;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content i {
	margin-top: -12px;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content h3,#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content h4 {
	margin: 0;
}

#portfolio .portfolio-item .portfolio-caption {
	max-width: 400px;
	margin: 0 auto;
	padding: 25px;
	text-align: center;
	background-color: #fff;
}

#portfolio .portfolio-item .portfolio-caption h4 {
	margin: 0;
	text-transform: none;
}

#portfolio .portfolio-item .portfolio-caption p {
	font-size: 16px;
	font-style: italic;
	margin: 0;
	font-family: 'Droid Serif',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}

#portfolio * {
	z-index: 2;
}

@media (min-width:767px) {
	#portfolio .portfolio-item {
		margin: 0 0 30px;
	}
}

.portfolio-modal .modal-dialog {
	margin: 1rem;
	max-width: 100vw;
}

.portfolio-modal .modal-content {
	padding: 100px 0;
	text-align: center;
}

.portfolio-modal .modal-content h2 {
	font-size: 3em;
	margin-bottom: 15px;
}

.portfolio-modal .modal-content p {
	margin-bottom: 30px;
}

.portfolio-modal .modal-content p.item-intro {
	font-size: 16px;
	font-style: italic;
	margin: 20px 0 30px;
	font-family: 'Droid Serif',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}

.portfolio-modal .modal-content ul.list-inline {
	margin-top: 0;
	margin-bottom: 30px;
}

.portfolio-modal .modal-content img {
	margin-bottom: 30px;
}

.portfolio-modal .modal-content button {
	cursor: pointer;
}

.portfolio-modal .close-modal {
	position: absolute;
	top: 25px;
	right: 25px;
	width: 75px;
	height: 75px;
	cursor: pointer;
	background-color: transparent;
}

.portfolio-modal .close-modal:hover {
	opacity: .3;
}

.portfolio-modal .close-modal .lr {
	z-index: 1051;
	width: 1px;
	height: 75px;
	margin-left: 35px;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	background-color: #212529;
}

.portfolio-modal .close-modal .lr .rl {
	z-index: 1052;
	width: 1px;
	height: 75px;
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
	background-color: #212529;
}
.team-member {
	margin-bottom: 50px;
	text-align: center;
}

.team-member img {
	width: 225px;
	height: 225px;
	border: 7px solid rgba(0,0,0,.1);
}

.team-member h4 {
	margin-top: 25px;
	margin-bottom: 0;
	text-transform: none;
}

.team-member p {
	margin-top: 0;
}

section#contact {
	background-color: #212529;
	background-image: url(../img/map-image.png);
	background-repeat: no-repeat;
	background-position: center;
}

section#contact .section-heading {
	color: #fff;
}

section#contact .form-group {
	margin-bottom: 25px;
}

section#contact .form-group input,section#contact .form-group textarea {
	padding: 20px;
}

section#contact .form-group input.form-control {
	height: auto;
}

section#contact .form-group textarea.form-control {
	height: 248px;
}

section#contact .form-control:focus {
	border-color: #fed136;
	-webkit-box-shadow: none;
	box-shadow: none;
}

section#contact ::-webkit-input-placeholder {
	font-weight: 700;
	color: #ced4da;
	font-family: Montserrat,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}

section#contact :-moz-placeholder {
	font-weight: 700;
	color: #ced4da;
	font-family: Montserrat,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}

section#contact ::-moz-placeholder {
	font-weight: 700;
	color: #ced4da;
	font-family: Montserrat,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}

section#contact :-ms-input-placeholder {
	font-weight: 700;
	color: #ced4da;
	font-family: Montserrat,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}

.footer {
	padding: 25px 0;
	text-align: center;
}

.footer span.copyright {
	font-size: 90%;
	line-height: 40px;
	text-transform: none;
	font-family: Montserrat,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}

.footer ul.quicklinks {
	font-size: 90%;
	line-height: 40px;
	margin-bottom: 0;
	text-transform: none;
	font-family: Montserrat,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}

ul.social-buttons {
	margin-bottom: 0;
}

ul.social-buttons li a {
	font-size: 20px;
	line-height: 50px;
	display: block;
	width: 50px;
	height: 50px;
	-webkit-transition: all .3s;
	transition: all .3s;
	color: #fff;
	border-radius: 100%;
	outline: 0;
	background-color: #212529;
}

ul.social-buttons li a:active,ul.social-buttons li a:focus,ul.social-buttons li a:hover {
	background-color: #fed136;
}
