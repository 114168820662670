.homepage-container {

    .masthead {
        height: 65vh;
        margin-bottom: -50px;

        .intro-text {
            height: 65vh;
            margin-top: 80px;

            .main-head-container {
                background: rgba(37,35,35,.5);
                opacity: 1 !important;
                letter-spacing: 3px;

                .intro-heading {
                    h1{
                        text-transform: capitalize;
                        font-family: 'Noticia Text', serif !important;
                        font-size: 3rem;
                        color: #fff;
                    }
                    margin-bottom: 0 !important;
                }
                .intro-lead-in {
                    p{
                        color: #fff;
                        font-size: 1rem;
                        font-family: 'Noticia Text', serif;
                        text-align: center;
                        font-style: normal !important;
                    }
                }
                .banner-btn{
                    position: relative;
                    display: inline-block;
                    text-decoration: none;
                    letter-spacing: 3px;
                    color: #fff;
                    background: var(--primary-color);
                    padding: .4rem .9rem;
                    border: 2px solid var(--primary-color);
                    -webkit-transition: all .3s linear;
                    transition: all .3s linear;
                    text-transform: uppercase;
                    cursor: pointer;
                    z-index: 999 !important;
                }
                .banner-btn:hover {
                    background: transparent;
                    border-color: white;
                    color: gold;
                }
                .underline{
                    width: 10rem;
                    height: 5px;
                    background: gold;
                    margin: .7rem auto;
                }
            }
        
            .tellme {
                color: black;

                &:hover {
                    color: white;
                }
            }
        }
    }
    
}

#service-container {
    padding-left: 50px;
    padding-right: 50px;

    .col-xl-3 {
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 50px;

        &:hover {
            cursor: pointer;
            text-decoration: none;
        }

        .material-icons {
            font-size: 1.5em !important;
        }

        h4 {
            color: black;
        }
    }

    .grow {
        max-height: 140px;
        overflow: hidden;
    }

    .grow:hover {
        max-height: 100%;
        transition: all 0.5s ease-in-out 0s;
    }
}

@media (max-width:768px) {
    .homepage-container {
        .masthead {
            height: 60vh;

            .intro-text {
                margin-top: -40px !important;
            }
    
            .grow {
                max-height: 100% !important;
            }
        }
    }

}

@media (max-width: 500px) {
    .homepage-container {
        .masthead {
            .intro-text {
                .main-head-container {
                    .banner-btn {
                        font-size: 14px !important;
                    }
                }
            }
        }
    }
}

@media (max-width:340px) {
    .homepage-container {
        .masthead {
            .intro-text {
                h1 {
                    font-size: 26px !important;
                }
                p {
                    font-size: 16px !important;
                }
            }
        }
    }
}

@media (min-width:768px) {
    .homepage-container {
        .masthead {
            .intro-text {
                margin-top: -100px;
            }
        }
    }
}

.fa-stack{
    .fa-circle{
        color: var(--primary-color) !important
    }
}