.MuiList-root {
    background-color: transparent !important;

    .service-list {
        margin-left: -10px !important;
        margin-top: -8px !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .service-sublist {
        margin-left: 30px !important;
        p{
            font-size: 14px !important;
        }
    }
    .material-icons {
        color: white;
    }

    p {
        color: white;
        margin-bottom: -8px !important;
    }

    .MuiSvgIcon-root {
        color: white;
    }

    .MuiTypography-root {
        margin-left: -20px !important;
    }

    .MuiTypography-body1 {
        text-transform: capitalize !important;
        color: white !important;
    }
}