.contact-form-wrapper {
  margin-bottom: 40px;
  background-color: #fff;
  margin-left: 50px;
  margin-right: 50px;
  padding-top: 50px;

  .title {
    font-size: 32px;
    color: #000;
    text-align: center;
    margin-bottom: 0;
  }

  .contact-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0px 50px;
    max-width: 1400px;
    margin: 0 auto;

    .half-input-field {
      flex: 0 0 calc(50% - 60px); /* Each item takes 50% of the container width with a bit of space between them */
      margin: 30px; /* Space between items */
      text-align: left;
    }

    .full-input-field {
      flex: 0 0 calc(100% - 60px);
    }

    .feel-free {
      font-size: 14px;
      margin-top: 20px;
      text-align: left;

      a {
        color: darkblue;
        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }
      }
    }

    .send-button {
      background-color: #fed136;
      color: #fff;
      font-size: 18px;
      font-weight: 500;
      margin-right: auto;
      margin-top: 30px;
      max-width: 200px;
      height: 40px;

      &:hover {
        cursor: pointer;
        opacity: 0.8;
        font-weight: 700;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .contact-form-wrapper {
    margin-left: 30px;
    margin-right: 30px;

    .contact-form {
      padding: 0px 50px;

      .half-input-field {
        flex: 0 0 calc(100% - 30px);
        margin: 15px;
      }

      .full-input-field {
        flex: 0 0 calc(100% - 30px);
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .contact-form-wrapper {
    .contact-form {
      padding: 0 30px;
    }
  }
}

@media only screen and (max-width: 350px) {
  .contact-form-wrapper {
    .contact-form {
      padding: 0 15px;

      .half-input-field {
        flex: 0 0 calc(100% - 10px);
        margin: 10px;
      }

      .full-input-field {
        flex: 0 0 calc(100% - 10px);
      }
    }
  }
}
