.about-us-container {
  padding-top: 20px;
}

.page-section {
    padding-top: 100px !important;
    padding-bottom: 0px !important;
}

.about-us-container {
  margin-top: 150px;
  margin-bottom: 50px;
  max-width: 100vw;
  overflow-x: hidden;
}
  
/* .container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
} */

.about {
  width: 90%;
  text-align: justify;
  margin: auto;
}

.section-heading {
  margin: 30px;
}

/* .row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
} */

/* .col-lg-12 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
} */

.rounded-circle {
  border-radius: 50%!important;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

img {
  vertical-align: middle;
  border-style: none;
}

.about-subheading {
  font-weight: 500 !important;
}

/* .page-section h3.section-subheading {
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
  margin-bottom: 75px;
  text-transform: none;
  font-family: 'Droid Serif',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
} */

.timeline{
  position:relative;
  padding:0;
  list-style:none
}
.timeline:before{
  position:absolute;
  top:0;
  bottom:0;
  left:40px;
  width:2px;
  margin-left:-1.5px;
  content:'';
  background-color:#e9ecef
}
.timeline>li{
  position:relative;
  min-height:50px;
  margin-bottom:50px
}
.timeline>li:after,.timeline>li:before{
  display:table;
  content:' '
}
.timeline>li:after{
  clear:both
}
.timeline>li .timeline-panel{
  position:relative;
  float:right;
  width:100%;
  padding:0 20px 0 100px;
  text-align:left
}
.timeline>li .timeline-panel:before{
  right:auto;
  left:-15px;
  border-right-width:15px;
  border-left-width:0
}
.timeline>li .timeline-panel:after{
  right:auto;
  left:-14px;
  border-right-width:14px;
  border-left-width:0
}
.timeline>li .timeline-image{
  position:absolute;
  z-index:100;
  left:0;
  width:80px;
  height:80px;
  margin-left:0;
  text-align:center;
  color:#fff;
  border:7px solid var(--primary-color) !important;
  border-radius:100%;
  background-color: var(--primary-color) !important;
}
.timeline>li .timeline-image h5{
  font-size:13px;
  line-height:14px;
  margin-top:12px;
  font-weight: bold;
}
.timeline>li.timeline-inverted>.timeline-panel{
  float:right;
  padding:0 20px 0 100px;
  text-align:left
}
.timeline>li.timeline-inverted>.timeline-panel:before{
  right:auto;
  left:-15px;
  border-right-width:15px;
  border-left-width:0
}
.timeline>li.timeline-inverted>.timeline-panel:after{
  right:auto;
  left:-14px;
  border-right-width:14px;
  border-left-width:0
}

.timeline .timeline-heading h4{
  margin-top:0;
  color:inherit;
}
.timeline .timeline-heading h4.subheading{
  text-transform:none
}
.timeline .timeline-body>p,.timeline .timeline-body>ul{
  margin-bottom:0
}

.choose-heading {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
}

.list-head {
  font-size: 20px;
  margin-bottom: 15px;
}

.health-row h4 {
  text-align: center;
}

.health-row .img-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.our-team {
  width: 600px;
  height: 300px;
}

.quality-para {
  margin-top: 50px;
}

.team-para {
  margin-top: 50px;
}

@media (min-width:768px){
  .timeline:before{
      left:50%
  }
  .timeline>li{
      min-height:100px;
      margin-bottom:120px
  }
  .timeline>li .timeline-panel{
      float:left;
      width:41%;
      padding:0 20px 20px 30px;
      text-align:right
  }
  .timeline>li .timeline-image{
      left:50%;
      width:110px;
      height:110px;
      margin-left:-55px
  }
  .timeline>li .timeline-image h5{
      font-size:15px;
      line-height:20px;
      margin-top:20px;
      font-weight: bold;
  }
  .timeline>li.timeline-inverted>.timeline-panel{
      float:right;
      padding:0 30px 20px 20px;
      text-align:left
  }
  .rounded-circle {
    margin-top: 15px;
  }
}
@media (min-width:992px){
  .timeline>li{
      min-height:100px
  }
  .timeline>li .timeline-panel{
      padding:0 20px 20px
  }
  .timeline>li .timeline-image{
      width:130px;
      height:130px;
      margin-left:-65px
  }
  .timeline>li .timeline-image h5{
      font-size:18px;
      line-height:26px;
      margin-top:20px;
      font-weight: bold;
  }
  .timeline>li.timeline-inverted>.timeline-panel{
      padding:0 20px 20px
  }
  .rounded-circle {
    margin-top: 25px;
  }
}
@media (min-width:1200px){
  .timeline>li{
      min-height:100px
  }
  .timeline>li .timeline-panel{
      padding:0 20px 20px 100px
  }
  .timeline>li .timeline-image{
      width:130px;
      height:130px;
      margin-left:-65px;
      font-weight: bold;
  }
  .timeline>li .timeline-image h5{
      margin-top:20px
  }
  .timeline>li.timeline-inverted>.timeline-panel{
      padding:0 100px 20px 20px
  }
  .rounded-circle {
    margin-top: 25px;
  }
}

p {
  text-align: justify;
}

@media (max-width: 1200px) {
  .why-choose-img {
    width: 450px;
  }

  .our-team {
    width: 500px;
    height: 200px;
  }

  .quality-image {
    width: 400px;
    height: 400px;
  }
}

@media (max-width: 992px) {
  .quality-image-container {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
  }
  .quality-para {
    margin-top: 0px;
  }
  .why-choose-img-container {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    margin-bottom: 20px;
  }
  .why-choose-row {
    flex-direction: column-reverse;
  }
  .quality-row {
    flex-direction: column-reverse;
  }
  .our-team-img-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .our-team-row {
    flex-direction: column-reverse;
  }
  .team-para {
    margin-top: 0px;
  }
  .env-column {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .about-us-container {
    padding-top: 20px;
  }
}

@media (max-width: 450px) {
  .quality-image {
    width: 300px;
    height: 300px;
  }
}