.dropdown-container {
    .service-button {
        outline: none;
        border: none;
        margin-top: -1px !important;
        font-size: 14px !important;
    }

    .dropdown-link {
        text-decoration: none;
        color: black;
        text-transform: lowercase;
        text-transform: capitalize;
    }
    li:hover{
        color: white;
        background-color: var(--primary-color);
    }
    .menu-dropdown{
        background-color: var(--primary-color);
        margin: 0 !important;
        padding: 0 !important;
        .dropdown-link{
            color: white;
        }
        .material-icons{
            margin-right: 10px;
        }
    }
    .MuiList-padding {
        margin-top: -3px !important;
    }
}
@media (max-width: 770px) {
  .MuiList-padding {
    padding: 0 !important;
    margin-top: 10px !important;
    margin-left: -5px !important;
    width: 100vw;
  }
}
