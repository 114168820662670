.service-item-main {
  .title-main {
    padding: 3rem 5rem;
    h2 {
      font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol', 'Noto Color Emoji';
      font-size: 2rem;
      margin-bottom: 1px;
      text-transform: capitalize;
      white-space: nowrap;
      color: var(--primary-color);
      text-align: center;
      font-weight: 500 !important;
      color: var(--primary-dark);
      @media (max-width: 992px) {
        margin-top: 20px;
      }
      @media (max-width: 600px) {
        font-size: 1.1rem !important;
      }
    }
  }
  .service-details {
    position: relative;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal !important;
}