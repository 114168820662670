.carousel-container {
    height: 30px;

    // .image-gallery {
    //     z-index: -99 !important;
    // }
    
    .image-gallery-content .image-gallery-slide .image-gallery-image {
        height: 65vh;
    }
    
    .image-gallery-slide .image-gallery-image {
        object-fit: cover;
    }
    
    .image-gallery-slides {
        z-index: -999 !important;
    }
}

@media (max-width:500px) {
    .carousel-container {
        .image-gallery-content .image-gallery-slide .image-gallery-image {
            height: 65vh;
        }
    }
}

@media (max-width:768px) {
    .carousel-container {
        .image-gallery-content .image-gallery-slide .image-gallery-image {
            height: 60vh;
        }
    }
}