.navbar-container {
  margin-bottom: 120px;
  #mainNav {
    background-color: var(--primary-color);
    height: 50px;
    margin-top: 80px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.25);

    #menu-button {
      outline: none;
      background: transparent;
      padding: 3px;

      i {
        font-size: 18px;
      }
    }

    #navbar-menu-list {
      display: flex;
      width: 500px !important;
      justify-content: space-around;
    }
    .nav-link:hover {
      color: cyan !important;
      cursor: pointer;
    }
  }

  .contact-float-btn {
    position: fixed;
    top: 160px;
    padding: 10px 15px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    transition: 0.5s;
    box-shadow: 0 0 5px #eee;
    z-index: 50;
    background-image: linear-gradient(to right, #f78b04, #f55005);
    justify-content: center;
    align-items: center;
    display: flex;

    &:hover {
      background-image: linear-gradient(to right, #f78b04, #f78b04, #f78b04, #f55005);
      cursor: pointer;
    }

    .contact-float-btn-txt {
      margin: 0;
      padding: 0;
      color: white;
      font-size: 18px;
      font-weight: 600;
    }
  }
}

@media only screen and (max-width: 992px) {
  .navbar-container {
    .contact-float-btn {
      top: 140px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .navbar-container {
    .contact-float-btn {
      padding: 5px 10px;

      .contact-float-btn-txt {
        font-size: 16px;
      }
    }
  }
}