.heading{
  padding-top: 30px;
  text-size-adjust: 12;
  text-align: center;
}

.page-section {
    padding: 100px 0;
  }
  
  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 30px;
    margin-right: auto;
    margin-left: auto;
  }
  
  .about {
    width: 100%;
    text-align: justify;
    margin: auto;
  }
  
  .section-heading {
    margin: 10px;
    text-align: left;
    font-size: 18px;
    color: black;
    text-size-adjust: auto;
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  
  .col-lg-12 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  .rounded-circle {
    border-radius: 50%!important;
   }
  
  .img-fluid {
    max-width: 100%;
    height: auto;
  }
  
  img {
    vertical-align: middle;
    border-style: none;
  }

  .about-subheading {
    font-weight: 500 !important;
  }
  
  /* .page-section h3.section-subheading {
    font-size: 16px;
    font-weight: 400;
    font-style: italic;
    margin-bottom: 75px;
    text-transform: none;
    font-family: 'Droid Serif',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
  } */
  
  .timeline{
    position:relative;
    padding:0;
    list-style:none
  }
  .timeline:before{
    position:absolute;
    top:0;
    bottom:0;
    left:40px;
    width:2px;
    margin-left:-1.5px;
    content:'';
    background-color:#e9ecef
  }
  .timeline>li{
    position:relative;
    min-height:50px;
    margin-bottom:50px
  }
  .timeline>li:after,.timeline>li:before{
    display:table;
    content:' '
  }
  .timeline>li:after{
    clear:both
  }
  .timeline>li .timeline-panel{
    position:relative;
    float:right;
    width:100%;
    padding:0 20px 0 100px;
    text-align:left
  }
  .timeline>li .timeline-panel:before{
    right:auto;
    left:-15px;
    border-right-width:15px;
    border-left-width:0
  }
  .timeline>li .timeline-panel:after{
    right:auto;
    left:-14px;
    border-right-width:14px;
    border-left-width:0
  }
  .timeline>li .timeline-image{
    position:absolute;
    z-index:100;
    left:0;
    width:80px;
    height:80px;
    margin-left:0;
    text-align:center;
    color:#fff;
    border:7px solid#fed136;
    border-radius:100%;
    background-color:#fed136
  }
  .timeline>li .timeline-image h5{
    font-size:13px;
    line-height:14px;
    margin-top:12px;
    font-weight: bold;
  }
  .timeline>li.timeline-inverted>.timeline-panel{
    float:right;
    padding:0 20px 0 100px;
    text-align:left
  }
  .timeline>li.timeline-inverted>.timeline-panel:before{
    right:auto;
    left:-15px;
    border-right-width:15px;
    border-left-width:0
  }
  .timeline>li.timeline-inverted>.timeline-panel:after{
    right:auto;
    left:-14px;
    border-right-width:14px;
    border-left-width:0
  }
  
  .timeline .timeline-heading h4{
    margin-top:0;
    color:inherit;
  }
  .timeline .timeline-heading h4.subheading{
    text-transform:none
  }
  .timeline .timeline-body>p,.timeline .timeline-body>ul{
    margin-bottom:0
  }

  p {
    color: gray;
  }

  @media (min-width:768px){
    .timeline:before{
        left:50%
    }
    .timeline>li{
        min-height:100px;
        margin-bottom:120px
    }
    .timeline>li .timeline-panel{
        float:left;
        width:41%;
        padding:0 20px 20px 30px;
        text-align:right
    }
    .timeline>li .timeline-image{
        left:50%;
        width:110px;
        height:110px;
        margin-left:-55px
    }
    .timeline>li .timeline-image h5{
        font-size:15px;
        line-height:20px;
        margin-top:20px;
        font-weight: bold;
    }
    .timeline>li.timeline-inverted>.timeline-panel{
        float:right;
        padding:0 30px 20px 20px;
        text-align:left
    }
    .rounded-circle {
      margin-top: 15px;
    }
  }
  @media (min-width:992px){
    .timeline>li{
        min-height:100px
    }
    .timeline>li .timeline-panel{
        padding:0 20px 20px
    }
    .timeline>li .timeline-image{
        width:130px;
        height:130px;
        margin-left:-65px
    }
    .timeline>li .timeline-image h5{
        font-size:18px;
        line-height:26px;
        margin-top:20px;
        font-weight: bold;
    }
    .timeline>li.timeline-inverted>.timeline-panel{
        padding:0 20px 20px
    }
    .rounded-circle {
      margin-top: 25px;
    }
  }
  @media (min-width:1200px){
    .timeline>li{
        min-height:100px
    }
    .timeline>li .timeline-panel{
        padding:0 20px 20px 100px
    }
    .timeline>li .timeline-image{
        width:130px;
        height:130px;
        margin-left:-65px;
        font-weight: bold;
    }
    .timeline>li .timeline-image h5{
        margin-top:20px
    }
    .timeline>li.timeline-inverted>.timeline-panel{
        padding:0 100px 20px 20px
    }
    .rounded-circle {
      margin-top: 25px;
    }
  }
  
  p {
    text-align: justify;
  }


