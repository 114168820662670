.career-outer {
  background-color: #f7f9ff;
  padding: 2px !important;
  margin: 0 !important;
  padding-top: 60px !important;
  .jobs-info{
    margin-top: 1rem;
    .section-heading {
      text-transform: capitalize;
    }
  }
  .main-container {
    margin-top: 1rem;
    margin-bottom: 5rem;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 15px;
    .about {
      background-color: #fff;
      .section-heading {
        text-align: left;
        text-transform: capitalize !important;
        font-weight: bold;
        margin-left: 0 !important;
        @media (max-width: 600px) {
          font-size: 1.5rem;
          font-weight: bold;
        }
      }
      .custom-d-flex .width-custom {
        width: 50%;
      }
      .custom-d-flex .width-full {
        width: 100%;
      }
      .form-group {
        margin-bottom: 0.5rem !important;
        // input,select {
        //   text-transform: capitalize !important;
        // }
        // input[type="email"]{
        //   text-transform: lowercase !important;
        // }
        // input[type="email"]::placeholder{
        //   text-transform: capitalize !important;
        // }
        input[type="file"] {
          width: 50%;
          border: 1px dashed grey;
          height: 2.9rem;
        }
      }
      .submit-outer {
        .btn {
          font-weight: normal !important;
        }
      }
      .loader{
        p{
          margin-top: 0 !important;
          padding-top: 0 !important;
          text-align: center;
        }
        p {
          animation: color-change 3s infinite;
        }
        
        @keyframes color-change {
          0% { color: var(--primary-color); }
          100% { color: green; }
        }
      }
      .success-outer{
        span{
          color: green;
          p{
            margin-top: 0 !important;
            padding-top: 0 !important;
            text-align: center;
          }
          i{
            font-weight: 600;
          }
        }
      }

      .failed-outer{
        span{
          color: rgb(204, 41, 0);
          p{
            margin-top: 0 !important;
            padding-top: 0 !important;
            text-align: center;
          }
          i{
            font-weight: 600;
          }
        }
      }

      @media (max-width: 992px) {
        padding: 1rem;
      }
      @media (max-width: 600px) {
        padding: 1rem 0;
      }
    }
  }
  .container-fluid {
    height: 55vh;
  }
  .service-wrap {
    background-image: url("../../assets/img/42281.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }
  @media (max-width: 600px) {
    padding: 1px 2rem !important;
  }
}
.custom-d-flex{
  display: flex;
  @media (max-width: 992px) {
    display: block;
    .form-group{
      margin-left: 0 !important;
      width: 100% !important;
    }
    input[type="file"] {
      width: 100% !important;
    }
  }
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid green;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
.label{
  margin-left: 4px;
  margin-bottom: 0;
}
.react-datepicker-wrapper
{
  display: block !important;
}
.react-datepicker__close-icon::after
{
  content: "\1F4C5" !important;
}
.upload-info{
  color: gray !important;
  font-size: 11px !important;
}