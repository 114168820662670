.MuiAccordionSummary-root.Mui-expanded{
  background-color: var(--primary-color) !important;
  p,
  .MuiIconButton-label{
    color: white;
  }
}
p{
  color: #000;
}
.MuiAccordion-rounded{
  border-radius: 2px;
}
.ControlledAccordion-heading-2{
  white-space: nowrap !important;
}