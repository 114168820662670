.list-main-outer {
  max-width: 100vw !important;
  .row {
    margin-bottom: 2rem;
    .col-lg-10 {
      padding: 1rem 4rem !important;
      .sublist {
        h3 {
          font-size: 1.8em !important;
          color: var(--primary-color);
          white-space: nowrap;
          @media (max-width: 700px) {
            font-size: 1.1rem !important;
            text-align: center;
          }
        }
        .outer-list {
          font-size: 1rem !important;
          font-weight: 400 !important;
          line-height: 2rem;
          @media (max-width: 700px) {
            font-size: 0.9rem !important;
          }
        }
        .inner-list {
          font-size: 1rem !important;
          font-weight: 350 !important;
          line-height: 2rem;
          @media (max-width: 700px) {
            font-size: 0.8rem !important;
          }
        }
      }
      @media (max-width: 700px) {
        padding: .2rem 1rem !important;
      }
    }
    .col-lg-1 {
      padding: 1rem 3rem !important;
      img {
        margin-left: auto;
        margin-right: auto;
        display: block;
      }
      @media (max-width: 700px) {
        padding: .2rem 1rem !important;
      }
    }
  }
  .row:nth-child(even) {
    background-color: #f5f5f5;
  }
}
